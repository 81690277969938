import { Component, OnInit } from '@angular/core';

import { QuizService } from '../services/quiz.service';
import { HelperService } from '../services/helper.service';
import { Option, Question, Quiz, QuizConfig } from '../models/index';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
  providers: [QuizService]
})
export class QuizComponent implements OnInit {
  quizes: any[];
  quiz: Quiz = new Quiz(null);
  mode = 'quiz';
  quizName: string;
  config: QuizConfig = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 3 * 60 * 60,  // indicates the time (in secs) in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': true,
    'showPager': true,
    'theme': 'none'
  };

  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  timer: any = null;
  startTime: Date;
  endTime: Date;
  ellapsedTime = '00:00';
  duration = '';
  numCorrect = 0;
  question: Question;

  constructor(private quizService: QuizService) { }

  ngOnInit() {
    this.quizes = this.quizService.getAll();
    this.quizName = this.quizes[0].id;
    this.loadQuiz(this.quizName);
  }

  loadQuiz(quizName: string) {
    this.quizService.get(quizName).subscribe(res => {

      const quizState = localStorage.getItem(this.quizName + "-state");
      if (quizState) {
        console.log("Current quiz exists!");
        this.quiz = JSON.parse(quizState);
      } 
      else {
        console.log("New quiz!");
        this.quiz = new Quiz(res);
      }
      this.pager.count = this.quiz.questions.length;
      this.startTime = new Date();
      this.ellapsedTime = '00:00';
      this.timer = setInterval(() => { this.tick(); }, 1000);
      this.duration = this.parseTime(this.config.duration);

      // Load page index
      const pageIndex = localStorage.getItem(this.quizName); 
      this.pager.index = +pageIndex;
      this.question = this.quiz.questions[this.pager.index];
    });
    this.mode = 'quiz';
  }

  tick() {
    const now = new Date();
    const diff = (now.getTime() - this.startTime.getTime()) / 1000;
    if (diff >= this.config.duration) {
      // this.onSubmit();
      this.mode = 'review';
    }
    this.ellapsedTime = this.parseTime(diff);
  }

  parseTime(totalSeconds: number) {
    let mins: string | number = Math.floor(totalSeconds / 60);
    let secs: string | number = Math.round(totalSeconds % 60);
    mins = (mins < 10 ? '0' : '') + mins;
    secs = (secs < 10 ? '0' : '') + secs;
    return `${mins}:${secs}`;
  }

  get filteredQuestions() {
    return (this.quiz.questions) ?
      this.quiz.questions.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }

  onSelect(question: Question, option: Option) {
    if (question.questionTypeId === 1) {
      question.options.forEach((x) => { if (x.id !== option.id) x.selected = false; });
    }

    if (this.config.autoMove) {
      this.goTo(this.pager.index + 1);
    }
  }

  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = 'quiz';
      localStorage.setItem(this.quizName, this.pager.index.toString());
      localStorage.setItem(this.quizName + "-state", JSON.stringify(this.quiz));
      this.question = this.quiz.questions[index];
    }
  }

  isAnswered(question: Question) {
    return question.options.find(x => x.selected) ? 'Answered' : 'Not Answered';
  };

  isCorrect(question: Question) {
    let result = 'correct';
    question.options.forEach(option => {
      if (option.selected && !option.isAnswer) result = 'wrong';
    })
    return result;
  };

  isCorrectOption(option: Option)  {
    let result = 'Not Answered';
    if (option.selected && option.isAnswer) result = 'correct';
    if (option.selected && !option.isAnswer) result = 'wrong';
    return result;
  }

  onSubmit() {
    let answers = [];
    this.quiz.questions.forEach(x => answers.push({ 'quizId': this.quiz.id, 'questionId': x.id, 'answered': x.answered }));

    // Post your data to the server here. answers contains the questionId and the users' answer.
    console.log(this.quiz.questions);
    this.numCorrect = this.quiz.questions.map(question => this.isCorrect(question)).filter(result => result === 'correct').length
    this.mode = 'result';
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  reset() {
    Swal.fire({
      title: 'Reset Exam?',
      text: "This will reset your progress!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        localStorage.removeItem(this.quizName + "-state"); 
        this.loadQuiz(this.quizName);
        Swal.fire(
          'Done!',
          'Your exam progress has been reset!',
          'success'
        )
      }
    })
  }
}
